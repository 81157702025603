// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content-height {
  height: calc(100vh - 53px);
  overflow: scroll;
}

.inputBody {
  border: 0.5px solid #e9e9e9 !important;
  border-radius: 3px;
}

@media screen and (max-width: 600px) {
  .main-content-height {
    height: calc(100vh - 135px);
    overflow: scroll;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,gBAAA;AACF;;AACA;EACE,sCAAA;EACA,kBAAA;AAEF;;AAAA;EACE;IACE,2BAAA;IACA,gBAAA;EAGF;AACF","sourcesContent":[".main-content-height {\n  height: calc(100vh - 53px);\n  overflow: scroll;\n}\n.inputBody {\n  border: 0.5px solid #e9e9e9 !important;\n  border-radius: 3px;\n}\n@media screen and (max-width: 600px) {\n  .main-content-height {\n    height: calc(100vh - 135px);\n    overflow: scroll;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import {
  errorAction,
  loadingAction,
  successSideEffectState,
  SideEffectSchema,
} from ".";

import { INFADashboardPayload } from "../interface";
/********************************** Actions *********************************************************/
export const Actions = {

  GET_EMP_ID_FROM_GRAPH: "GET_EMP_ID_FROM_GRAPH",
  GET_EMP_ID_FROM_GRAPH_LOADING: "GET_EMP_ID_FROM_GRAPH_LOADING",
  GET_EMP_ID_FROM_GRAPH_ERROR: "GET_EMP_ID_FROM_GRAPH_ERROR",
  GET_EMP_ID_FROM_GRAPH_SUCCESS: "GET_EMP_ID_FROM_GRAPH_SUCCESS",

  //User Access Token
  GET_USER_ROLES_LIST: "GET_USER_ROLES_LIST",
  GET_USER_ROLES_LIST_LOADING: "GET_USER_ROLES_LIST_LOADING",
  GET_USER_ROLES_LIST_ERROR: "GET_USER_ROLES_LIST_ERROR",
  GET_USER_ROLES_LIST_SUCCESS: "GET_USER_ROLES_LIST_SUCCESS",

  //User Role
  GET_USER_ROLE: "GET_USER_ROLE",
  GET_USER_ROLE_LOADING: "GET_USER_ROLE_LOADING",
  GET_USER_ROLE_ERROR: "GET_USER_ROLE_ERROR",
  GET_USER_ROLE_SUCCESS: "GET_USER_ROLE_SUCCESS",

  //User Position
  GET_USER_POSITION: "GET_USER_POSITION",
  GET_USER_POSITION_LOADING: "GET_USER_POSITION_LOADING",
  GET_USER_POSITION_ERROR: "GET_USER_POSITION_ERROR",
  GET_USER_POSITION_SUCCESS: "GET_USER_POSITION_SUCCESS",

  //NFA Dashboard
  POST_NFA_DASHBOARD: "POST_NFA_DASHBOARD",
  POST_NFA_DASHBOARD_LOADING: "POST_NFA_DASHBOARD_LOADING",
  POST_NFA_DASHBOARD_ERROR: "POST_NFA_DASHBOARD_ERROR",
  POST_NFA_DASHBOARD_SUCCESS: "POST_NFA_DASHBOARD_SUCCESS",
};

/********************************** Interfaces *******************************************************/

// User Access Token

export interface IGetUserRolesList extends SideEffectSchema {
  data: {
    Role: string;
    Type: string;
    BU: string;
    Position: string;
  }[];
}

// User Role

export interface IGetUserRoleObject {
  ROLE_ID: string;
  ROLE_DESCRIPTION: string;
}

export interface IGetUserRole extends SideEffectSchema {
  data: IGetUserRoleObject[];
}

// User Position

export interface IGetUserPositionObject {
  position: string;
  is_ACTIVE: string;
  user_ID: string;
  inserted_BY: string;
  inserted_ON: string;
  updated_BY: string | null;
  updated_ON: string;
  sap_ROLE: string | null;
  lvl: string;
  positiondesc: string;
  grp_TYPE: string;
  role_ID: string;
}

export interface IGetUserPosition extends SideEffectSchema {
  data: IGetUserPositionObject[];
}

//NFA Dashboard
export interface IPostNfaDashboard extends SideEffectSchema {
  status_OUT: string;
  response_OUT: {
    allNFA: number;
    twoDay: number;
    nfaPending: number;
    threeDay: number;
    nfaRejected: number;
    avgRealization: number;
    approvalCount: number;
    retailCount: number;
    nfaPendingWithRSM: number;
    oneDay: number;
    nfaPendingWithTSM: number;
    nfaPendingWithSPM: number;
    nfaApproved: number;
    moreThanListedDays: number;
    allNfaPending: number;
    nfaPendingWithNPM: number;
  };
}

/********************************** Actions Creator *************************************************/


/***
 * Get Emp ID Using Graph API
 */

export interface IGetEmployeeUsingGraph extends SideEffectSchema {
  employeeId: string;
  mailNickname: string;
  userPrincipalName: string;
  id: string;
  mail: string;
  givenName: string;
  surname: string;
  displayName: string;
  manager: {
    employeeId: string;
    mailNickname: string;
    userPrincipalName: string;
    id: string;
    mail: string;
    department: string;
    displayName: string;
    givenName: string;
    surname: string;
    jobTitle: string;
    mobilePhone: string;
  }
}

export const getEmployeeIdUsingGraphAction = (
  access_token: string,
  context: any
) => {
  return {
    type: Actions.GET_EMP_ID_FROM_GRAPH,
    payload: { access_token, context },
  };
};
export const getEmployeeIdUsingGraphLoadingAction = () =>
  loadingAction(Actions.GET_EMP_ID_FROM_GRAPH_LOADING);
export const getEmployeeIdUsingGraphErrorAction = (error: string) =>
  errorAction(Actions.GET_EMP_ID_FROM_GRAPH_ERROR, error);
export const getEmployeeIdUsingGraphSuccessAction = (data: any) => {
  const payload: IGetEmployeeUsingGraph = {
    ...data,
    ...successSideEffectState,
  };
  return {
    type: Actions.GET_EMP_ID_FROM_GRAPH_SUCCESS,
    payload: payload,
  };
};

// User Access Token

export const getUserRolesListAction = (empId: string) => {
  return {
    type: Actions.GET_USER_ROLES_LIST,
    payload: empId
  };
};

export const getUserRolesListLoadingAction = () =>
  loadingAction(Actions.GET_USER_ROLES_LIST_LOADING);
export const getUserRolesListErrorAction = (error: string) =>
  errorAction(Actions.GET_USER_ROLES_LIST_ERROR, error);
export const getUserRolesListSuccessAction = (data: any) => {
  const payload: IGetUserRolesList = {
    data: data,
    ...successSideEffectState,
  };
  return {
    type: Actions.GET_USER_ROLES_LIST_SUCCESS,
    payload: payload,
  };
};

// User Role

export const getUserRoleAction = (
  RoleType: string,
  AppId: string,
  // access_token: string
) => {
  return {
    type: Actions.GET_USER_ROLE,
    payload: { RoleType, AppId },
  };
};

export const getUserRoleLoadingAction = () =>
  loadingAction(Actions.GET_USER_ROLE_LOADING);
export const getUserRoleErrorAction = (error: string) =>
  errorAction(Actions.GET_USER_ROLE_ERROR, error);
export const getUserRoleSuccessAction = (data: any) => {
  const payload: IGetUserRole = {
    data: data,
    ...successSideEffectState,
  };
  return {
    type: Actions.GET_USER_ROLE_SUCCESS,
    payload: payload,
  };
};

// User Position

export const getUserPositionAction = (pRole: string) => {
  return {
    type: Actions.GET_USER_POSITION,
    payload: { pRole },
  };
};

export const getUserPositionLoadingAction = () =>
  loadingAction(Actions.GET_USER_POSITION_LOADING);
export const getUserPositionErrorAction = (error: string) =>
  errorAction(Actions.GET_USER_POSITION_ERROR, error);
export const getUserPositionSuccessAction = (data: any) => {
  const payload: IGetUserPosition = {
    data: data,
    ...successSideEffectState,
  };
  return {
    type: Actions.GET_USER_POSITION_SUCCESS,
    payload: payload,
  };
};

//NFA Dashboard
export const postNfaDashboardAction = (
  data: INFADashboardPayload,
) => {
  return {
    type: Actions.POST_NFA_DASHBOARD,
    payload: { data },
  };
};

export const postNfaDashboardLoadingAction = () =>
  loadingAction(Actions.POST_NFA_DASHBOARD_LOADING);
export const postNfaDashboardErrorAction = (error: string) =>
  errorAction(Actions.POST_NFA_DASHBOARD_ERROR, error);
export const postNfaDashboardSuccessAction = (data: any) => {
  const payload: IPostNfaDashboard = {
    ...data,
    ...successSideEffectState,
  };
  return {
    type: Actions.POST_NFA_DASHBOARD_SUCCESS,
    payload: payload,
  };
};

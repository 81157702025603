import axios from "axios";
import { API_BASE_URL, API_SECRET_TOKEN, NFA_USER_API } from "../config";
import { msTeamsEmail } from "../App";

interface QuerySchema {
  [key: string]: string;
}

export interface HttpServiceSchema {
  httpGet: (path: string, query: QuerySchema, payload: any) => Promise<any>;
  httpDelete: (path: string, query: QuerySchema, payload: any) => Promise<any>;
  httpPost: (path: string, query: QuerySchema, payload: any) => Promise<any>;
  httpPut: (path: string, query: QuerySchema, payload: any) => Promise<any>;
}

const handleErrorResp = (err: any) => {
  return err.response && err.response.data !== "string"
    ? Promise.reject(err.response.data)
    : Promise.reject(err);
};

/**
 * This function makes a HTTP GET request using the args provided
 * @param {string} path the path to the microservice endpoint
 * @param {QuerySchema} query optional query containing params in keyvalue pairs
 * @returns {Promise} promise containing response data or errors
 */

/**
 * This function makes a HTTP POST request using the args provided
 * @param {string} path the path to the microservice endpoint
 * @param {QuerySchema} query optional query containing params in keyvalue pairs
 * @param {QuerySchema} payload optional body containing the request payload
 * @returns {Promise} promise containing response data or errors
 */
export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

export const axiosInstance = axios.create();
axiosInstance.defaults.timeout = 1000 * 60 * 5;
axiosInstance.interceptors.request.use(
  async (config: any) => {
    const currentTimeStamp = new Date().toJSON();
    const afterFiveTimeStamp = new Date();
    const tokenConfig = {
      headers: {
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
        // "x-teams-origin": "http://localhost:3000",
      },
    };
    let tokenPromise = "";
    let oidUser = (localStorage.getItem("requested_subject") === null) ? "" : localStorage.getItem("requested_subject") as string;
    if (new Date(currentTimeStamp) > new Date(localStorage.getItem("afterFiveTimeStamp") as string)) {
      const resp = (await axios.get(API_BASE_URL + NFA_USER_API.GET_ACCESS_TOKEN + `?email=${msTeamsEmail}&oid=${oidUser}`, tokenConfig)).data;
      tokenPromise = resp.access_token;
      oidUser = resp.requested_subject;
    } else {
      tokenPromise = localStorage.getItem("token") as string;
    }

    afterFiveTimeStamp.setMinutes(afterFiveTimeStamp.getMinutes() + 5);
    ((tokenPromise !== undefined) && new Date(currentTimeStamp) > new Date(localStorage.getItem("afterFiveTimeStamp") as string)) && localStorage.setItem("afterFiveTimeStamp", afterFiveTimeStamp.toJSON());
    (tokenPromise !== undefined) && localStorage.setItem("token", tokenPromise);
    (tokenPromise !== undefined) && localStorage.setItem("requested_subject", oidUser);
    config.headers["Authorization"] = "Bearer " + tokenPromise;
    config.headers["Content-Type"] = "application/json";
    config.headers["token"] = API_SECRET_TOKEN;
    config.headers["x-teams-origin"] = window.location.origin;
    // config.headers["x-teams-origin"] = "http://localhost:3000";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const httpGet = async (url: string, config = {}) => {
  return await axiosInstance
    .get(url, config)
    .then((response) => response)
    .catch((err) => handleErrorResp(err));
};

export const httpGetGraph = async (url: string, config = {}) => {
  return await axios
    .get(url, config)
    .then((response) => response)
    .catch((err) => handleErrorResp(err));
};

export const httpPost = async (url: string, payload = {} as any, config = {} as any) => {
  return await axiosInstance
    .post(url, payload, config)
    .then((response) => response)
    .catch((err) => handleErrorResp(err));
};

export const httpPost2 = async (url: string, payload = {}, config = {}) => {
  return await axiosInstance
    .post(url, payload, config)
    .then((response) => response)
    .catch((err) => handleErrorResp(err));
};

export const httpPut = async (url: string, payload = {}, config = {}) => {
  return await axiosInstance
    .put(url, payload, config)
    .then((response) => response)
    .catch((err) => handleErrorResp(err));
};

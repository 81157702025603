import { all, fork } from "redux-saga/effects";

import watchUserDataSaga from "./userSaga";
import watchNfaFilterDataSaga from "./nfaFilterSaga";
import watchNfaApprovalDataSaga from "./nfaApprovalSaga";
import watchNfaReportDataSaga from "./nfaReportSaga";

function* rootSaga() {
  yield all([
    watchUserDataSaga(),
    watchNfaFilterDataSaga(),
    watchNfaApprovalDataSaga(),
    watchNfaReportDataSaga(),
  ]);
}
export default rootSaga;

import { Flex, Loader } from "@fluentui/react-northstar";
import React from "react";

type Props = {};

const LazyLoading = (props: Props) => {
    return (
        <Flex padding="padding.medium" style={{ height: "100vh" }} vAlign="center" hAlign="center" fill> <Loader size="largest" /></Flex>
    );
};

export default LazyLoading;
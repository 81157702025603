import { Box, Button, CloseIcon, Dialog, Divider, Flex, Loader, SkeletonLine, Text } from "@fluentui/react-northstar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import ErrorComponent from "../ErrorComponent";
import DataNotFound from "../DataNotFound";
import { selectedPositionData, setMainActivePosition } from "../../redux/actions/appConfigration";
import { IState, msTeamsEmail } from "../../App";
import { getUserPreferenceDataSuccessAction } from "../../redux/actions/NfaApprovalActions";

interface IMainHeaders {
    QueryData: IState;
}

interface IChangePositionActive {
    isActive: boolean;
    selectItem: {
        Role: string;
        Type: string;
        BU: string;
        Position: string;
    };
}

const MainHeader: React.FC<IMainHeaders> = (props) => {
    const [changPosActive, setChangPosActive] = React.useState<IChangePositionActive>({
        isActive: false, selectItem: {
            Role: "",
            Type: "",
            BU: "",
            Position: "",
        }
    });
    const dispatch = useDispatch();
    const state = useSelector((state: RootSchema) => state);
    const roleList = state.userData.getUserRolesListData;
    const oidUser = localStorage.getItem("requested_subject");

    return (
        <React.Fragment>
            <Flex styles={({ theme: { siteVariables } }) => ({
                background: siteVariables.bodyBackground === "#fff" ? "linear-gradient(180deg, #FFFFFF 0%, #D9DBF7 153.57%)" : siteVariables.bodyBackground
            })} vAlign="center" space="between" padding="padding.medium">
                <Flex>{oidUser !== null ? <Text color="brand" weight="semibold">Welcome,  <Text size="medium" weight="regular" content={`${props.QueryData.displayName === undefined ? "" : props.QueryData.displayName} (${oidUser})`} /></Text> : <SkeletonLine width="150px" height="1.2rem" />}</Flex>
                {state.appConfig.dimension.deviceWidth > 600 && <Text weight="bold" color="brand" content={state.appConfig.userMainActivePosition.Position} />}
                <Button primary content={"Change Position"} onClick={() => setChangPosActive({ ...changPosActive, isActive: true })} />
            </Flex>
            <Divider fitted />
            <Dialog open={changPosActive?.isActive}
                header="Select Position"
                headerAction={{
                    content: <CloseIcon />,
                    onClick: () => setChangPosActive({ isActive: false, selectItem: { BU: "", Type: "", Position: "", Role: "" } })
                }}
                color="red"
                content={
                    <Flex column >
                        <Divider fitted />
                        <Flex fill padding="padding.medium">
                            <Text styles={{ width: "100%", maxWidth: "30px" }} weight="bold" content="#" />
                            <Text styles={{ width: "100%", maxWidth: "70px" }} weight="bold" content="BU" />
                            <Text styles={{ width: "100%", maxWidth: "70px" }} weight="bold" content="Role" />
                            <Text weight="bold" content="Available Position" />
                        </Flex>
                        <Divider fitted />
                        <Box styles={{ maxHeight: "400px", overflow: "auto" }}>
                            {roleList.isSuccess && Array.isArray(roleList.data) && roleList.data.length ?
                                roleList.data.map((item, index, array) => {
                                    const showBreak = index !== array.length - 1;
                                    return (
                                        <React.Fragment key={index}>
                                            <Flex
                                                styles={({ theme: { siteVariables } }) => ((state.appConfig.userMainActivePosition.Position + state.appConfig.userMainActivePosition.Role == item.Position + item.Role) || changPosActive.selectItem.Position == item.Position ? {
                                                    background: siteVariables.bodyBackground === "#fff" ? "#f4f5ff 0 0 no-repeat padding-box" : siteVariables.bodyBackground,
                                                    borderRadius: "5px", border: "1px solid rgb(91, 95, 199)",
                                                } : {})}
                                                key={index} fill padding="padding.medium"
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    setChangPosActive({ ...changPosActive, selectItem: item });
                                                }}
                                            >
                                                <Text styles={{ width: "100%", maxWidth: "30px" }} weight="semibold" content={index + 1} />
                                                <Text styles={{ width: "100%", maxWidth: "70px" }} weight="semibold" content={item.BU} />
                                                <Text styles={{ width: "100%", maxWidth: "70px" }} weight="semibold" content={item.Role} />
                                                <Text weight="semibold" content={item.Position} />
                                            </Flex >
                                            {showBreak && <Divider fitted />
                                            }
                                        </React.Fragment>
                                    );
                                }) :
                                roleList.isSuccess && Array.isArray(roleList.data) && !roleList.data.length ? <DataNotFound /> :
                                    roleList.isSuccess && !Array.isArray(roleList.data) ? <ErrorComponent message={roleList.data} /> :
                                        <Loader />
                            }
                        </Box>
                    </Flex>

                }
                cancelButton="Cancel"
                onCancel={() => setChangPosActive({ isActive: false, selectItem: { BU: "", Type: "", Position: "", Role: "" } })}
                confirmButton={{ content: "Submit", disabled: changPosActive.selectItem.Position === "" }}
                onConfirm={() => {
                    state.appConfig.userMainActivePosition.Role !== changPosActive.selectItem.Role && dispatch(getUserPreferenceDataSuccessAction({ isSuccess: false }));
                    dispatch(selectedPositionData([]));
                    dispatch(setMainActivePosition(changPosActive.selectItem));
                    setChangPosActive({ isActive: false, selectItem: { BU: "", Type: "", Position: "", Role: "" } });
                }}
                styles={{ padding: "1rem" }}
            />
        </React.Fragment >
    );
};

export default MainHeader;
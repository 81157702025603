import { combineReducers } from "redux";
import {
  Actions,
  IGetUserPreferenceData,
  ISetUserPreferenceData,
  IPostNfaDetailData,
  IPostDownloadNfaDetailData,
  IPostNfaApproveData,
  IPostNfaRejectData,
} from "../actions/NfaApprovalActions";

//Get User Preference Reducer
export const getUserPreferenceDataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_USER_PREFERENCE_DATA_LOADING:
    case Actions.GET_USER_PREFERENCE_DATA_SUCCESS:
    case Actions.GET_USER_PREFERENCE_DATA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

//Set User Preference Reducer
export const setUserPreferenceDataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.SET_USER_PREFERENCE_DATA_LOADING:
    case Actions.SET_USER_PREFERENCE_DATA_SUCCESS:
    case Actions.SET_USER_PREFERENCE_DATA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

//NFA Details Reducer
export const postNfaDetailDataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_NFA_DETAIL_DATA_LOADING:
    case Actions.GET_NFA_DETAIL_DATA_SUCCESS:
    case Actions.GET_NFA_DETAIL_DATA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

//export to excel API Reducer
export const postDownloadNfaDetailDataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_DOWNLOAD_NFA_DETAIL_DATA_LOADING:
    case Actions.GET_DOWNLOAD_NFA_DETAIL_DATA_SUCCESS:
    case Actions.GET_DOWNLOAD_NFA_DETAIL_DATA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

//Submit API(Approve) Reducer
export const postNfaApproveDataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.POST_NFA_APPROVE_DATA_LOADING:
    case Actions.POST_NFA_APPROVE_DATA_SUCCESS:
    case Actions.POST_NFA_APPROVE_DATA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

//Submit API(send for reconsideration, reject) Reducer
export const postNfaRejectDataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.POST_NFA_REJECT_DATA_LOADING:
    case Actions.POST_NFA_REJECT_DATA_SUCCESS:
    case Actions.POST_NFA_REJECT_DATA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export interface INfaApprovalData {
  getUserPreferenceData: IGetUserPreferenceData;
  setUserPreferenceData: ISetUserPreferenceData;
  nfaDetailData: IPostNfaDetailData;
  downloadNfaDetailData: IPostDownloadNfaDetailData;
  nfaApproveData: IPostNfaApproveData;
  nfaRejectData: IPostNfaRejectData;
}

const nfaApprovalData = combineReducers({
  getUserPreferenceData: getUserPreferenceDataReducer,
  setUserPreferenceData: setUserPreferenceDataReducer,
  nfaDetailData: postNfaDetailDataReducer,
  downloadNfaDetailData: postDownloadNfaDetailDataReducer,
  nfaApproveData: postNfaApproveDataReducer,
  nfaRejectData: postNfaRejectDataReducer,
});
export default nfaApprovalData;

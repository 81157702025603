import {
  errorAction,
  loadingAction,
  successSideEffectState,
  SideEffectSchema,
} from ".";

import {
  IUserPreferenceDataPayload,
  IGetNFADetailDataPayload,
  IDownloadNFADetailDataPayload,
  INFAApprovePayload,
  INFARejectPayload,
  ISetUserPreferencePayload,
} from "../interface";

/********************************** Actions *********************************************************/
export const Actions = {
  //Get User Preference
  GET_USER_PREFERENCE_DATA: "GET_USER_PREFERENCE_DATA",
  GET_USER_PREFERENCE_DATA_LOADING: "GET_USER_PREFERENCE_DATA_LOADING",
  GET_USER_PREFERENCE_DATA_ERROR: "GET_USER_PREFERENCE_DATA_ERROR",
  GET_USER_PREFERENCE_DATA_SUCCESS: "GET_USER_PREFERENCE_DATA_SUCCESS",

  //Set User Preference
  SET_USER_PREFERENCE_DATA: "SET_USER_PREFERENCE_DATA",
  SET_USER_PREFERENCE_DATA_LOADING: "SET_USER_PREFERENCE_DATA_LOADING",
  SET_USER_PREFERENCE_DATA_ERROR: "SET_USER_PREFERENCE_DATA_ERROR",
  SET_USER_PREFERENCE_DATA_SUCCESS: "SET_USER_PREFERENCE_DATA_SUCCESS",

  //NFA Details
  GET_NFA_DETAIL_DATA: "GET_NFA_DETAIL_DATA",
  GET_NFA_DETAIL_DATA_LOADING: "GET_NFA_DETAIL_DATA_LOADING",
  GET_NFA_DETAIL_DATA_ERROR: "GET_NFA_DETAIL_DATA_ERROR",
  GET_NFA_DETAIL_DATA_SUCCESS: "GET_NFA_DETAIL_DATA_SUCCESS",

  //export to excel API
  GET_DOWNLOAD_NFA_DETAIL_DATA: "GET_DOWNLOAD_NFA_DETAIL_DATA",
  GET_DOWNLOAD_NFA_DETAIL_DATA_LOADING: "GET_DOWNLOAD_NFA_DETAIL_DATA_LOADING",
  GET_DOWNLOAD_NFA_DETAIL_DATA_ERROR: "GET_DOWNLOAD_NFA_DETAIL_DATA_ERROR",
  GET_DOWNLOAD_NFA_DETAIL_DATA_SUCCESS: "GET_DOWNLOAD_NFA_DETAIL_DATA_SUCCESS",

  //Submit API(Approve)
  POST_NFA_APPROVE_DATA: "POST_NFA_APPROVE_DATA",
  POST_NFA_APPROVE_DATA_LOADING: "POST_NFA_APPROVE_DATA_LOADING",
  POST_NFA_APPROVE_DATA_ERROR: "POST_NFA_APPROVE_DATA_ERROR",
  POST_NFA_APPROVE_DATA_SUCCESS: "POST_NFA_APPROVE_DATA_SUCCESS",

  //Submit API(send for reconsideration, reject)
  POST_NFA_REJECT_DATA: "POST_NFA_REJECT_DATA",
  POST_NFA_REJECT_DATA_LOADING: "POST_NFA_REJECT_DATA_LOADING",
  POST_NFA_REJECT_DATA_ERROR: "POST_NFA_REJECT_DATA_ERROR",
  POST_NFA_REJECT_DATA_SUCCESS: "POST_NFA_REJECT_DATA_SUCCESS",
};

/********************************** Interfaces *******************************************************/

//Get User Preference

export interface IPreferredColumn {
  columnName: string;
  columnValue: string;
  referredColumn: null | string;
  showActionBtns: null | string;
  isInputFeild: boolean;
  isFrozen: null | string;
  isChecked: boolean;
  isDropdown: boolean;
}

export interface IRequestPayloadEntity {
  role: string;
  module: string;
  preferredColumn: IPreferredColumn[];
  userId: string;
}

export interface IGetUserPreferenceData extends SideEffectSchema {
  status_OUT: string;
  response_OUT: {
    count: number;
    requestPayloadEntity: IRequestPayloadEntity[];
  };
  role: string;
}

//Set User Preference
export interface ISetUserPreferenceData extends SideEffectSchema {
  status_OUT: string;
  response_OUT: string;
}
//NFA Details
export interface IPostNfaDetailObject {
  nfaId: string;
  dealerState: null | string;
  requester: string;
  dealerName: string;
  ageing: string;
  model: string;
  dealSize: string;
  exShowroomTml: string;
  landingPrice: null | string;
  realisation: string;
  tsmComments: null | string;
  approverCash: string;
  nfaRequestDate: string;
  expiryDate: string;
  status: string;
  netSupportPerVehicle: string;
  requestedDealSize: string;
  totalSupportSought: string;
  spmApprvdAmtPerVehicle: null | string;
  spmApprDealSize: null | string;
  spmComments: null | string;
  rsmComments: null | string;
  rsmApprvdAmt: null | string;
  rsmApprvdAmtPerVehicle: null | string;
  rsmApprvdDealSize: null | string;
  dealerCode: string;
  accountName: string;
  location: string;
  application: string;
  tmlFleetSize: null | string;
  overallFleetSize: null | string;
  vehicleRegState: null | string;
  dealType: string;
  lob: string;
  ppl: string;
  pl: string;
  vc: string;
  productDescription: string;
  source: string;
  competitor: null | string;
  exShowroomCompetition: null | string;
  discount: string;
  dealerMargin: string;
  retention: string;
  financier: string;
  ltv: string;
  customerName: string;
  cancelledNfa: null | string;
  optyId: string;
  contactNo: string;
  voidRemark: null | string;
  amc: number;
  cash: number;
  extendedWarranty: number;
  spareParts: number;
  insurance: null | string;
  onsiteService: number;
  fleetman: number;
  delight: number;
  subvention: number;
  liveDeal: string;
  rejectionReason: null | string;
  lastApprover: string;
  actionTaken: string;
  approverAmc: number;
  approverExtendedWarranty: number;
  approverSpareParts: number;
  approverInsurance: null | string;
  approverOnsiteService: number;
  approverFleetman: number;
  approverDelight: number;
  approverFinSubvention: number;
  approverTmlDiscount: number;
  pdorActivityId: string;
  pdorStatus: string;
  pdorRaisedDate: string;
  pdorResponseDate: string;
  pdorResponse: null | string;
  pdorCompetitionName: null | string;
  pdorComptModelName: null | string;
  pdorComptVehiclePrice: null | string;
  pdorComptLandingPrice: null | string;
  position: string;
  role: string;
  area: null | string;
  pdorTmlDiscountReq: null | string;
  pdorDsmRemark: null | string;
  deviationFlag: null | string;
  deviationRemark: null | string;
  dsmComments: null | string;
  tsmApprvdAmt: null | string;
  tsmApprvdAmtPerVehicle: null | string;
  tsmApprvdDealSize: null | string;
  spmApprvdAmt: null | string;
  lobhComments: null | string;
  lobhApprvdAmtPerVehicle: null | string;
  lobhApprvdAmtDealSize: null | string;
  lobhApprvdAmt: null | string;
  statusCode: string;
  chassisMfgYear: string;
  tsmDf: null | string;
  tsmDr: null | string;
  spmDf: null | string;
  spmDr: null | string;
  rsmDf: null | string;
  rsmDr: null | string;
  tmlLandingPrice: string;
  creatorPosition: string;
  keyAccount: null | string;
  comments?: null | string;
  remark?: null | string;
  anchorPricing: null | string;
  dealSweetnerReco: null | string;
  mustWinPrice: null | string;
  secondLevelOffer: null | string;
  customerType?: null | string;
  approverFms: string;
  deal_SLAB_TO_DS1: string;
  deal_SLAB_TO_DS2: string;
  deal_SLAB_TO_DS3: string;
  deal_SLAB_FROM_DS1: string;
  deal_SLAB_FROM_DS2: string;
  deal_SLAB_FROM_DS3: string;
  dg_DS1: string;
  dg_DS2: string;
  dg_DS3: string;
  deal_SLAB: string;
  grid_VALUE: string;
}
export interface IPostNfaDetailData extends SideEffectSchema {
  STATUS_OUT: string;
  RESPONSE_OUT: {
    count: number;
    list: IPostNfaDetailObject[];
  };
}

//export to excel API

export interface IPostDownloadNfaDetailData extends SideEffectSchema {
  data: string;
}

//Submit API(Approve)
export interface IPostNfaApproveData extends SideEffectSchema {
  status_OUT: string;
  response_OUT: {
    successList: string[];
    failedList: string[];
  };
}

//Submit API(send for reconsideration, reject)
export interface IPostNfaRejectData extends SideEffectSchema {
  status_OUT: string;
  response_OUT: {
    successList: string[];
    failedList?: string[];
  };
}

/********************************** Actions Creator *************************************************/

//User Preference
export const getUserPreferenceDataAction = (
  data: IUserPreferenceDataPayload,

) => {
  return {
    type: Actions.GET_USER_PREFERENCE_DATA,
    payload: { data },
  };
};

export const getUserPreferenceDataLoadingAction = () =>
  loadingAction(Actions.GET_USER_PREFERENCE_DATA_LOADING);
export const getUserPreferenceDataErrorAction = (error: string) =>
  errorAction(Actions.GET_USER_PREFERENCE_DATA_ERROR, error);
export const getUserPreferenceDataSuccessAction = (data: any) => {
  const payload: IGetUserPreferenceData = {
    ...data,
    ...successSideEffectState,
  };
  return {
    type: Actions.GET_USER_PREFERENCE_DATA_SUCCESS,
    payload: payload,
  };
};

//Set User Preference
export const setUserPreferenceDataAction = (
  data: ISetUserPreferencePayload,
) => {
  return {
    type: Actions.SET_USER_PREFERENCE_DATA,
    payload: { data },
  };
};

export const setUserPreferenceDataLoadingAction = () =>
  loadingAction(Actions.SET_USER_PREFERENCE_DATA_LOADING);
export const setUserPreferenceDataErrorAction = (error: string) =>
  errorAction(Actions.SET_USER_PREFERENCE_DATA_ERROR, error);
export const setUserPreferenceDataSuccessAction = (data: any) => {
  const payload: ISetUserPreferenceData = {
    ...data,
    ...successSideEffectState,
  };
  return {
    type: Actions.SET_USER_PREFERENCE_DATA_SUCCESS,
    payload: payload,
  };
};

//NFA Details
export const postNfaDetailDataAction = (
  data: IGetNFADetailDataPayload,
) => {
  return {
    type: Actions.GET_NFA_DETAIL_DATA,
    payload: { data },
  };
};

export const postNfaDetailDataLoadingAction = () =>
  loadingAction(Actions.GET_NFA_DETAIL_DATA_LOADING);
export const postNfaDetailDataErrorAction = (error: string) =>
  errorAction(Actions.GET_NFA_DETAIL_DATA_ERROR, error);
export const postNfaDetailDataSuccessAction = (data: any) => {
  const payload: IPostNfaDetailData = {
    ...data,
    ...successSideEffectState,
  };
  return {
    type: Actions.GET_NFA_DETAIL_DATA_SUCCESS,
    payload: payload,
  };
};

//export to excel API
export const postDownloadNfaDetailDataAction = (
  data: IDownloadNFADetailDataPayload,
) => {
  return {
    type: Actions.GET_DOWNLOAD_NFA_DETAIL_DATA,
    payload: { data },
  };
};

export const postDownloadNfaDetailDataLoadingAction = () =>
  loadingAction(Actions.GET_DOWNLOAD_NFA_DETAIL_DATA_LOADING);
export const postDownloadNfaDetailDataErrorAction = (error: string) =>
  errorAction(Actions.GET_DOWNLOAD_NFA_DETAIL_DATA_ERROR, error);
export const postDownloadNfaDetailDataSuccessAction = (data: any) => {
  const payload: IPostDownloadNfaDetailData = {
    ...data,
    ...successSideEffectState,
  };
  return {
    type: Actions.GET_DOWNLOAD_NFA_DETAIL_DATA_SUCCESS,
    payload: payload,
  };
};

//Submit API(Approve)
export const postNfaApproveDataAction = (
  data: INFAApprovePayload,
) => {
  return {
    type: Actions.POST_NFA_APPROVE_DATA,
    payload: { data },
  };
};

export const postNfaApproveDataLoadingAction = () =>
  loadingAction(Actions.POST_NFA_APPROVE_DATA_LOADING);
export const postNfaApproveDataErrorAction = (error: string) =>
  errorAction(Actions.POST_NFA_APPROVE_DATA_ERROR, error);
export const postNfaApproveDataSuccessAction = (data: any) => {
  const payload: IPostNfaApproveData = {
    ...data,
    ...successSideEffectState,
  };
  return {
    type: Actions.POST_NFA_APPROVE_DATA_SUCCESS,
    payload: payload,
  };
};

//Submit API(send for reconsideration, reject)
export const postNfaRejectDataAction = (
  data: INFARejectPayload,
) => {
  return {
    type: Actions.POST_NFA_REJECT_DATA,
    payload: { data },
  };
};

export const postNfaRejectDataLoadingAction = () =>
  loadingAction(Actions.POST_NFA_REJECT_DATA_LOADING);
export const postNfaRejectDataErrorAction = (error: string) =>
  errorAction(Actions.POST_NFA_REJECT_DATA_ERROR, error);
export const postNfaRejectDataSuccessAction = (data: any) => {
  const payload: IPostNfaRejectData = {
    ...data,
    ...successSideEffectState,
  };
  return {
    type: Actions.POST_NFA_REJECT_DATA_SUCCESS,
    payload: payload,
  };
};

export const API_BASE_URL = process.env.REACT_APP_API_URL;
// export const API_BASE_URL = process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;

// export const API_SECRET_TOKEN = process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_API_SECRET : process.env.REACT_APP_API_SECRET;
export const API_SECRET_TOKEN = process.env.REACT_APP_API_SECRET;

export const NFA_USER_API = {
  GET_ACCESS_TOKEN: "/nfa/token",
  GET_ROLE_DATA: "/nfa/getRolesForUser?",
  GET_ROLES_LIST: "/nfa/rolelist?",
  GET_POSITION_DATA: "/nfa/getPositionForRole?",
  GET_DASHBOARD_DATA: "/nfa/dashboard"
};

export const NFA_FILTERS_API = {
  GET_LOB_DATA: "/nfa/lobmappingforposition",
  GET_PPL_DATA: "/nfa/pplmappingforposition",
  GET_PL_DATA: "/nfa/plmappingforposition",
  GET_DEALER_CODE_DATA: "/nfa/getDealersForRole",
  GET_STATE_DATA: "/nfa/getStates",
  GET_COMPETITOR_DATA: "/nfa/getCompetitorList",
  GET_REGION_DATA: "/nfa/getRegions"
};

export const NFA_APPROVAL_API = {
  GET_USER_PREFERENCE_DATA: "/nfa/getuserdetails",
  SET_USER_PREFERENCE_DATA: "/nfa/setUserPreference",
  GET_NFA_DETAIL_DATA: "/nfa/getnfadetails",
  GET_DOWNLOAD_NFA_DETAIL_DATA: "/nfa/downloadnfadash",
  POST_NFA_APPROVE: "/nfa/approval",
  POST_NFA_REJECT: "/nfa/nfareversal"
};

export const NFA_REPORT_API = {
  GET_BUDGE_INFO_DATA: "/nfa/kitty",
  GET_PROVISION_DETAIL_DATA: "/nfa/getprovisiondetails"
};

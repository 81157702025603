import {
  errorAction,
  loadingAction,
  successSideEffectState,
  SideEffectSchema,
} from ".";

import {
  ILOBDataPayload,
  IPPLDataPayload,
  IDealerCodeDataPayload,
  IStateDataPayload,
  ICompetitorDataPayload,
  IRegionDataPayload,
  IPLDataPayload,
} from "../interface";

/********************************** Actions *********************************************************/
export const Actions = {
  //LOB
  POST_LOB_DATA: "POST_LOB_DATA",
  POST_LOB_DATA_LOADING: "POST_LOB_DATA_LOADING",
  POST_LOB_DATA_ERROR: "POST_LOB_DATA_ERROR",
  POST_LOB_DATA_SUCCESS: "POST_LOB_DATA_SUCCESS",

  //PPL
  POST_PPL_DATA: "POST_PPL_DATA",
  POST_PPL_DATA_LOADING: "POST_PPL_DATA_LOADING",
  POST_PPL_DATA_ERROR: "POST_PPL_DATA_ERROR",
  POST_PPL_DATA_SUCCESS: "POST_PPL_DATA_SUCCESS",

  //Deader Code
  POST_DEALER_CODE_DATA: "POST_DEALER_CODE_DATA",
  POST_DEALER_CODE_DATA_LOADING: "POST_DEALER_CODE_DATA_LOADING",
  POST_DEALER_CODE_DATA_ERROR: "POST_DEALER_CODE_DATA_ERROR",
  POST_DEALER_CODE_DATA_SUCCESS: "POST_DEALER_CODE_DATA_SUCCESS",

  //State
  POST_STATE_DATA: "POST_STATE_DATA",
  POST_STATE_DATA_LOADING: "POST_STATE_DATA_LOADING",
  POST_STATE_DATA_ERROR: "POST_STATE_DATA_ERROR",
  POST_STATE_DATA_SUCCESS: "POST_STATE_DATA_SUCCESS",

  //Competitor
  POST_COMPETITOR_DATA: "POST_COMPETITOR_DATA",
  POST_COMPETITOR_DATA_LOADING: "POST_COMPETITOR_DATA_LOADING",
  POST_COMPETITOR_DATA_ERROR: "POST_COMPETITOR_DATA_ERROR",
  POST_COMPETITOR_DATA_SUCCESS: "POST_COMPETITOR_DATA_SUCCESS",

  //Region
  POST_REGION_DATA: "POST_REGION_DATA",
  POST_REGION_DATA_LOADING: "POST_REGION_DATA_LOADING",
  POST_REGION_DATA_ERROR: "POST_REGION_DATA_ERROR",
  POST_REGION_DATA_SUCCESS: "POST_REGION_DATA_SUCCESS",

  //PL
  POST_PL_DATA: "POST_PL_DATA",
  POST_PL_DATA_LOADING: "POST_PL_DATA_LOADING",
  POST_PL_DATA_ERROR: "POST_PL_DATA_ERROR",
  POST_PL_DATA_SUCCESS: "POST_PL_DATA_SUCCESS",
};

/********************************** Interfaces *******************************************************/

//LOB
export interface IPostLOBData extends SideEffectSchema {
  data: {
    STATUS_OUT: string;
    RESPONSE_OUT: {
      lobDetails: {
        bu: string;
        lob: string;
        lobId: string;
      }[];
    };
  };

}

//PPL
export interface IPostPPLData extends SideEffectSchema {
  data: {
    STATUS_OUT: string;
    RESPONSE_OUT: {
      pplDetails: {
        bu: string;
        pplId: string;
        ppl: string;
        lob: string;
        lobId: string;
      }[];
    };
  };
}

//Deader Code
export interface IPostDealerCodeObject {
  BU: string;
  AREA: string;
  DEALERNAME: string;
  ZONE: null | string;
  DLRCODE: string;
  REGION: string;
}

export interface IPostDealerCodeData extends SideEffectSchema {
  data: IPostDealerCodeObject[];
}

//State
export interface IPostStateObject {
  STATE: string;
  BU: string;
  ZONE: string;
  REGION: string;
}

export interface IPostStateData extends SideEffectSchema {
  data: IPostStateObject[];
}

//Competitor
export interface IPostCompetitorData extends SideEffectSchema {
  data: {
    STATUS_OUT: string;
    RESPONSE_OUT: {
      competitorList: string[];
    };
  };
}

//Region
export interface IPostRegionObject {
  BU: string;
  REGION: string;
}

export interface IPostRegionData extends SideEffectSchema {
  data: IPostRegionObject[];
}

//PL
export interface IPostPLData extends SideEffectSchema {
  data: {
    STATUS_OUT: string;
    RESPONSE_OUT: {
      plDetails: {
        bu: string;
        plId: string;
        pplId: string;
        pl: string;
        ppl: string;
        lob: string;
        lobId: string;
      }[];
    };
  };
}

/********************************** Actions Creator *************************************************/

//LOB
export const postLobDataAction = (
  data: ILOBDataPayload,
) => {
  return {
    type: Actions.POST_LOB_DATA,
    payload: { data },
  };
};

export const postLobDataLoadingAction = () =>
  loadingAction(Actions.POST_LOB_DATA_LOADING);
export const postLobDataErrorAction = (error: string) =>
  errorAction(Actions.POST_LOB_DATA_ERROR, error);
export const postLobDataSuccessAction = (data: any) => {
  const payload: IPostLOBData = {
    data: data,
    ...successSideEffectState,
  };
  return {
    type: Actions.POST_LOB_DATA_SUCCESS,
    payload: payload,
  };
};

//PPL
export const postPplDataAction = (
  data: IPPLDataPayload,
) => {
  return {
    type: Actions.POST_PPL_DATA,
    payload: { data },
  };
};

export const postPplDataLoadingAction = () =>
  loadingAction(Actions.POST_PPL_DATA_LOADING);
export const postPplDataErrorAction = (error: string) =>
  errorAction(Actions.POST_PPL_DATA_ERROR, error);
export const postPplDataSuccessAction = (data: any) => {
  const payload: IPostPPLData = {
    data: data,
    ...successSideEffectState,
  };
  return {
    type: Actions.POST_PPL_DATA_SUCCESS,
    payload: payload,
  };
};

//Deader Code
export const postDealerCodeDataAction = (
  data: IDealerCodeDataPayload,
) => {
  return {
    type: Actions.POST_DEALER_CODE_DATA,
    payload: { data },
  };
};

export const postDealerCodeDataLoadingAction = () =>
  loadingAction(Actions.POST_DEALER_CODE_DATA_LOADING);
export const postDealerCodeDataErrorAction = (error: string) =>
  errorAction(Actions.POST_DEALER_CODE_DATA_ERROR, error);
export const postDealerCodeDataSuccessAction = (data: any) => {
  const payload: IPostDealerCodeData = {
    data: data,
    ...successSideEffectState,
  };
  return {
    type: Actions.POST_DEALER_CODE_DATA_SUCCESS,
    payload: payload,
  };
};

//State
export const postStateDataAction = (
  data: IStateDataPayload,
) => {
  return {
    type: Actions.POST_STATE_DATA,
    payload: { data },
  };
};

export const postStateDataLoadingAction = () =>
  loadingAction(Actions.POST_STATE_DATA_LOADING);
export const postStateDataErrorAction = (error: string) =>
  errorAction(Actions.POST_STATE_DATA_ERROR, error);
export const postStateDataSuccessAction = (data: any) => {
  const payload: IPostStateData = {
    data: data,
    ...successSideEffectState,
  };
  return {
    type: Actions.POST_STATE_DATA_SUCCESS,
    payload: payload,
  };
};

//Competitor
export const postCompetitorDataAction = (
  data: ICompetitorDataPayload,
) => {
  return {
    type: Actions.POST_COMPETITOR_DATA,
    payload: { data },
  };
};

export const postCompetitorDataLoadingAction = () =>
  loadingAction(Actions.POST_COMPETITOR_DATA_LOADING);
export const postCompetitorDataErrorAction = (error: string) =>
  errorAction(Actions.POST_COMPETITOR_DATA_ERROR, error);
export const postCompetitorDataSuccessAction = (data: any) => {
  const payload: IPostCompetitorData = {
    data: data,
    ...successSideEffectState,
  };
  return {
    type: Actions.POST_COMPETITOR_DATA_SUCCESS,
    payload: payload,
  };
};

//Region
export const postRegionDataAction = (
  data: IRegionDataPayload,
) => {
  return {
    type: Actions.POST_REGION_DATA,
    payload: { data },
  };
};

export const postRegionDataLoadingAction = () =>
  loadingAction(Actions.POST_REGION_DATA_LOADING);
export const postRegionDataErrorAction = (error: string) =>
  errorAction(Actions.POST_REGION_DATA_ERROR, error);
export const postRegionDataSuccessAction = (data: any) => {
  const payload: IPostRegionData = {
    data: data,
    ...successSideEffectState,
  };
  return {
    type: Actions.POST_REGION_DATA_SUCCESS,
    payload: payload,
  };
};

//PL
export const postPlDataAction = (
  data: IPLDataPayload,
) => {
  return {
    type: Actions.POST_PL_DATA,
    payload: { data },
  };
};

export const postPlDataLoadingAction = () =>
  loadingAction(Actions.POST_PL_DATA_LOADING);
export const postPlDataErrorAction = (error: string) =>
  errorAction(Actions.POST_PL_DATA_ERROR, error);
export const postPlDataSuccessAction = (data: any) => {
  const payload: IPostPLData = {
    data: data,
    ...successSideEffectState,
  };
  return {
    type: Actions.POST_PL_DATA_SUCCESS,
    payload: payload,
  };
};

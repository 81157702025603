import { Box, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import { IState } from "../../App";
import "./sidebar.scss";
import { useNavigate } from "react-router-dom";
import { ReportIcon } from "../../assets/icons";
import MainHeader from "../MainHeader";
import { RootSchema } from "../../redux/reducer";
import { useSelector } from "react-redux";
import { AllNfaIcon, DashboardMobileIcon, NFAApprovalMobileIcon, } from "../../assets/icons";

interface ISidebarProps {
    QueryData: IState;
    app_env?: string;
    children?: JSX.Element;
}

const Sidebar: React.FC<ISidebarProps> = (props: ISidebarProps) => {
    const navigate = useNavigate();
    const pathName = window.location.pathname;
    const state = useSelector((state: RootSchema) => state);
    const SidebarData = [
        {
            sequence: 0,
            isActive: true,
            path: "/" + `?email=${props.QueryData.eMail}`,
            text: "Dashboard",
            icon: <DashboardMobileIcon />,
            subMenu: {
                isMenu: false,
                subItems: []
            }
        },
        {
            sequence: 1,
            isActive: true,
            path: "/nfa-approval" + `?email=${props.QueryData.eMail}`,
            text: "NFA Approval",
            icon: <NFAApprovalMobileIcon />,
            subMenu: {
                isMenu: false,
                subItems: []
            }
        },
        {
            sequence: 2,
            icon: <ReportIcon />,
            isActive: false,
            path: "/report-mis",
            text: "Report-MIS",
            subMenu: {
                isMenu: false,
                subItems: [
                    {
                        path: "",
                        text: "",
                    },
                    {
                        path: "",
                        text: "",
                    },
                ]
            }
        },
        {
            sequence: 3,
            isActive: false,
            path: "/nfa-provision-report",
            text: "NFA Provision Report",
            icon: <AllNfaIcon />,
            subMenu: {
                isMenu: false,
                subItems: []
            }
        },
        {
            sequence: 4,
            isActive: false,
            path: "/budget",
            text: "Budget",
            icon: <AllNfaIcon />,
            subMenu: {
                isMenu: false,
                subItems: []
            }
        },
    ];

    const handleRoute = (path: string) => {
        return navigate(path);
    };

    return (
        <React.Fragment>
            {state.appConfig.dimension.deviceWidth >= 600 ? <Box className='sidebar-root'>
                <Flex column className='sidebar-main'
                    styles={({ theme: { siteVariables } }) => ({
                        background: siteVariables.colorScheme.grey.backgroundActive,
                    })}>
                    {SidebarData.filter((item) => item.isActive).map((item, index) => {
                        return (<Box key={index} className='sidebar_box'>
                            <Flex space="between" vAlign='center' className={(item.subMenu.isMenu && item.subMenu.subItems.some(sub => sub.path === pathName)) || pathName === item.path.split("?")[0] ? "sidebar-menu active" : "sidebar-menu"} onClick={() => handleRoute(item.path)}>
                                <Text className='sidebar_text' content={item.text} />
                            </Flex>
                        </Box>);
                    })}
                </Flex>
                <Box className='content' styles={({ theme: { siteVariables } }) => ({ background: siteVariables.bodyBackground })} >
                    <MainHeader QueryData={props.QueryData} />
                    {props.children}
                </Box>
            </Box > :
                <Box className='sidebar-root'>
                    <Box className='content' styles={({ theme: { siteVariables } }) => ({ background: siteVariables.bodyBackground })} >
                        <MainHeader QueryData={props.QueryData} />
                        {props.children}
                    </Box>
                    <Box className="sidebar-mobile" styles={({ theme: { siteVariables } }) => ({ background: siteVariables.bodyBackground })} >
                        {SidebarData.filter((item) => item.isActive).map((item, index) => {
                            return (
                                <Flex key={index} gap="gap.smaller" className={(item.subMenu.isMenu && item.subMenu.subItems.some(sub => sub.path === pathName)) || pathName === item.path.split("?")[0] ? "sidebar-menu active" : "sidebar-menu"} column vAlign='center' hAlign="center" onClick={() => handleRoute(item.path)}>
                                    {item.icon}
                                    <Text content={item.text} size="medium" />
                                </Flex>

                            );
                        })}
                    </Box>
                </Box>
            }
        </React.Fragment >
    );
};

export default Sidebar;




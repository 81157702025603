// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-root {
  display: flex;
  height: 100%;
  min-height: 100vh;
}
.sidebar-root .sidebar-main {
  padding: 1px;
}
.sidebar-root .sidebar_box {
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #616161;
  width: 200px;
}
.sidebar-root .sidebar_box:not(:last-child) {
  margin-bottom: 0.2rem;
}
.sidebar-root .sidebar-menu {
  padding: 0.8rem 1rem;
}
.sidebar-root .sidebar-menu:hover {
  background: #5b62c0;
  color: #ffffff;
  border-radius: 4px;
}
.sidebar-root .sidebar-menu:hover svg {
  fill: white;
}
.sidebar-root .active {
  background: #5b62c0;
  color: #ffffff;
  border-radius: 4px;
}
.sidebar-root .active svg {
  fill: white;
}
.sidebar-root .content {
  position: relative;
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
}
.sidebar-root .sidebar-mobile {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  border-top: 0.5px solid #cccccc;
  z-index: 100;
  display: flex;
}
.sidebar-root .sub-menu-content {
  line-height: 35px;
  padding-left: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/sidebar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,iBAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,YAAA;AAAJ;AAEI;EACE,qBAAA;AAAN;AAIE;EACE,oBAAA;AAFJ;AAII;EACE,mBAAA;EACA,cAAA;EACA,kBAAA;AAFN;AAIM;EACE,WAAA;AAFR;AAOE;EACE,mBAAA;EACA,cAAA;EACA,kBAAA;AALJ;AAOI;EACE,WAAA;AALN;AASE;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EAEA,cAAA;AARJ;AAWE;EACE,eAAA;EACA,SAAA;EACA,QAAA;EACA,OAAA;EACA,+BAAA;EAEA,YAAA;EACA,aAAA;AAVJ;AAaE;EACE,iBAAA;EACA,kBAAA;AAXJ","sourcesContent":[".sidebar-root {\n  display: flex;\n  height: 100%;\n  min-height: 100vh;\n\n  .sidebar-main {\n    padding: 1px;\n  }\n\n  .sidebar_box {\n    cursor: pointer;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 20px;\n    color: #616161;\n    width: 200px;\n\n    &:not(:last-child) {\n      margin-bottom: 0.2rem;\n    }\n  }\n\n  .sidebar-menu {\n    padding: 0.8rem 1rem;\n\n    &:hover {\n      background: #5b62c0;\n      color: #ffffff;\n      border-radius: 4px;\n\n      svg {\n        fill: white;\n      }\n    }\n  }\n\n  .active {\n    background: #5b62c0;\n    color: #ffffff;\n    border-radius: 4px;\n\n    svg {\n      fill: white;\n    }\n  }\n\n  .content {\n    position: relative;\n    flex-grow: 1;\n    height: 100vh;\n    // padding: 0.8rem;\n    overflow: auto;\n  }\n\n  .sidebar-mobile {\n    position: fixed;\n    bottom: 0;\n    right: 0;\n    left: 0;\n    border-top: 0.5px solid #cccccc;\n    // background: transparent;\n    z-index: 100;\n    display: flex;\n  }\n\n  .sub-menu-content {\n    line-height: 35px;\n    padding-left: 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

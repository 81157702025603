interface IConstant {
    ERROR_MSG_SET_USER_PREFERENCE: string;
    USER_DO_NOT_HAVE_ACCESS: string;
    API_FAILED_MESSAGE: string;
}
export const Constant: IConstant = {
    ERROR_MSG_SET_USER_PREFERENCE: "User Preferences Not Updated",
    USER_DO_NOT_HAVE_ACCESS: "You do not have access",
    API_FAILED_MESSAGE: "Something went Wrong..."
};

export const getQueryVariable = (variable: string): string | null => {
    const query = window && window.location.search.substring(1);
    const vars = query.split("&");
    for (const varPairs of vars) {
        const pair = varPairs.split("=");
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null;
};
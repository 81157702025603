import { combineReducers, Action } from "redux";
import appReducer, { IAppConfig } from "./appReducer";
import userData, { IUserData } from "./userReducer";
import nfaFilterData, { INfaFilterData } from "./nfaFilterReducer";
import nfaApprovalData, { INfaApprovalData } from "./nfaApprovalReducer";
import nfaReportData, { INfaReportData } from "./nfaReportReducer";
// Default reducer

function defaultReducer(state = {}, _action: any) {
  return state;
}
export interface RootSchema {
  default: {};
  appConfig: IAppConfig;
  userData: IUserData;
  nfaFilterData: INfaFilterData;
  nfaApprovalData: INfaApprovalData;
  nfaReportData: INfaReportData;
}

const rootReducer = combineReducers({
  default: defaultReducer,
  appConfig: appReducer,
  userData: userData,
  nfaFilterData: nfaFilterData,
  nfaApprovalData: nfaApprovalData,
  nfaReportData: nfaReportData,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;

import { IGetUserPositionObject } from "../actions/UserActions";
import { Actions } from "../actions/appConfigration";

export interface IAppConfig {
  path: string;
  dimension: { deviceWidth: number; deviceHeight: number; };
  isBudgetField?: boolean;
  isDocumentExpand?: boolean;
  selectedPosition: IGetUserPositionObject[];
  userRole: string;
  userMainActivePosition: {
    Role: string;
    Type: string;
    BU: string;
    Position: string;
  },
}

const appReducer = (state = { path: "", dimension: {}, isBudgetField: false, isDocumentExpand: false, selectedPosition: [], userRole: "", userMainActivePosition: {} }, action: any) => {
  switch (action.type) {
    case Actions.SET_PATH:
      return {
        ...state,
        path: action.payload
      };
    case Actions.DEVICE_DIMESIONS:
      return {
        ...state,
        dimension: action.payload,
      };
    case Actions.SET_BUDGET_FIELD_VIEW:
      return {
        ...state,
        isBudgetField: action.payload,
      };
    case Actions.SET_DOCUMENT_EXPAND:
      return {
        ...state,
        isDocumentExpand: action.payload,
      };
    case Actions.SELECTED_POSITION_DATA:
      return {
        ...state,
        selectedPosition: action.payload,
      };
    case Actions.USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      };
    case Actions.MAIN_ACTIVE_POSITION:
      return {
        ...state,
        userMainActivePosition: action.payload
      };
    default:
      return state;
  }
};

export default appReducer;

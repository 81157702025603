import {
  errorAction,
  loadingAction,
  successSideEffectState,
  SideEffectSchema,
} from ".";

import { IBudgeInfoPayload, IProvisionDetailPayload } from "../interface";

/********************************** Actions *********************************************************/
export const Actions = {
  //Budget Info
  POST_BUDGE_INFO_DATA: "POST_BUDGE_INFO_DATA",
  POST_BUDGE_INFO_DATA_LOADING: "POST_BUDGE_INFO_DATA_LOADING",
  POST_BUDGE_INFO_DATA_ERROR: "POST_BUDGE_INFO_DATA_ERROR",
  POST_BUDGE_INFO_DATA_SUCCESS: "POST_BUDGE_INFO_DATA_SUCCESS",

  //Provision Detail
  POST_PROVISION_DETAIL_DATA: "POST_PROVISION_DETAIL_DATA",
  POST_PROVISION_DETAIL_DATA_LOADING: "POST_PROVISION_DETAIL_DATA_LOADING",
  POST_PROVISION_DETAIL_DATA_ERROR: "POST_PROVISION_DETAIL_DATA_ERROR",
  POST_PROVISION_DETAIL_DATA_SUCCESS: "POST_PROVISION_DETAIL_DATA_SUCCESS",
};

/********************************** Interfaces *******************************************************/

//Budget Info

export interface IPostBudgetInfoData extends SideEffectSchema {
  status_OUT: string;
  response_OUT: {
    totalBudgetAllocated: number;
    kittyAvailable: number;
    kittyUtilized: number;
    totalNFAPendingAmt: number;
  };
}

//Provision Detail
export interface IPostProvisionDetailObject {
  liveDeal: string;
  vehicleRegState: null;
  finSubvention: number;
  finalApprAmount: null;
  year: string;
  reqDelight: number;
  lobHeadApprAmount: null;
  model: string;
  cash: number;
  nfaApprovalDate: null;
  requester: string;
  chassisMfgYear: string;
  spmComment: null;
  optyId: string;
  custody: string;
  customerName: string;
  productDesc: string;
  reqFleetMan: number;
  tsmApprovedAmount: string;
  rejectionReason: string;
  lob: string;
  status: string;
  pdorCompName: null;
  compExShowroom: number;
  rsmComment: null;
  financier: string;
  compDiscount: number;
  dealerCode: string;
  rsmApprovedAmount: null;
  spmApprAmount: null;
  reqSubvention: number;
  delight: number;
  retention: number;
  netSupportSought: number;
  rsmApprovedDealSize: null;
  lastApprover: string;
  totalRequestedAmt: number;
  pdorDsmRemark: null;
  reqOnsiteWarranty: number;
  ppl: string;
  tsmComment: null;
  reqAmc: number;
  nfaNo: string;
  onsiteService: number;
  lobHeadApprDealSize: null;
  spmTotalApprAmt: null;
  insurance: null;
  lobHeadTotalApprAmt: null;
  compLandingPrice: null;
  pdorTmlDiscRequired: null;
  spmApprDealSize: null;
  dealerMargin: number;
  source: string;
  pdorResponseDate: string;
  activityId: string;
  pdorStatus: string;
  tmlDiscount: number;
  finalApprDealSize: null;
  dealerState: string;
  requestedDealSize: number;
  area: string;
  pdorCompVehPrice: null;
  dealerName: string;
  rsmTotalApprAmt: null;
  dealType: string;
  vc: string;
  pdorRaisedDate: string;
  pdorCompLandingPrice: null;
  month: string;
  nfaRequestDate: string;
  amc: number;
  totalContribution: number;
  fleetman: number;
  accountName: null;
  pdorResponse: null;
  reqCash: number;
  remark: null;
  sparePArts: number;
  expiryDate: string;
  lobHeadComment: null;
  overallFleetSize: number;
  reqIsuarance: null;
  tsmTotalApprAmt: number;
  tmlFleetSize: number;
  competitor: null;
  contactNumber: string;
  tmlExShowroomPrice: number;
  totalSupportSought: number;
  keyAccount: null;
  extendedWarranty: number;
  comments: null;
  dealSize: number;
  tsmApprDealSize: number;
  nfaCreationDate: string;
  realization: number;
  ltv: string;
  reqExtendedWarranty: number;
  pdorCompModelName: null;
  additionalCustomer: string;
  application: string;
  cancelledNfa: null;
  location: string;
}
export interface IPostProvisionDetailData extends SideEffectSchema {
  status_OUT: string;
  response_OUT: {
    count: number;
    requestPayloadEntity: IPostProvisionDetailObject[];
  };
}

/********************************** Actions Creator *************************************************/

//Budget Info
export const postBudgetInfoeDataAction = (
  data: IBudgeInfoPayload,
) => {
  return {
    type: Actions.POST_BUDGE_INFO_DATA,
    payload: { data },
  };
};

export const postBudgetInfoDataLoadingAction = () =>
  loadingAction(Actions.POST_BUDGE_INFO_DATA_LOADING);
export const postBudgetInfoDataErrorAction = (error: string) =>
  errorAction(Actions.POST_BUDGE_INFO_DATA_ERROR, error);
export const postBudgetInfoDataSuccessAction = (data: any) => {
  const payload: IPostBudgetInfoData = {
    ...data,
    ...successSideEffectState,
  };
  return {
    type: Actions.POST_BUDGE_INFO_DATA_SUCCESS,
    payload: payload,
  };
};

//Provision Detail
export const postProvisionDetailDataAction = (
  data: IProvisionDetailPayload,
  access_token: string
) => {
  return {
    type: Actions.POST_PROVISION_DETAIL_DATA,
    payload: { data, access_token },
  };
};

export const postProvisionDetailDataLoadingAction = () =>
  loadingAction(Actions.POST_PROVISION_DETAIL_DATA_LOADING);
export const postProvisionDetailDataErrorAction = (error: string) =>
  errorAction(Actions.POST_PROVISION_DETAIL_DATA_ERROR, error);
export const postProvisionDetailDataSuccessAction = (data: any) => {
  const payload: IPostProvisionDetailData = {
    ...data,
    ...successSideEffectState,
  };
  return {
    type: Actions.POST_PROVISION_DETAIL_DATA_SUCCESS,
    payload: payload,
  };
};

import { Flex, Text, Image } from "@fluentui/react-northstar";
import AccessDeniedIcon from "../../assets/img/accessDenied.png";
import React from "react";

const AccessDenied = () => {
    return (
        <React.Fragment>
            <Flex column gap="gap.medium" vAlign='center' hAlign="center" fill styles={{ height: "100Vh" }}>
                <Image src={AccessDeniedIcon} height="200px" width="200px" />
                <Text content="You do not have access" size="largest" color="brand" weight='bold' />
                <Text content="Please connect with team admin to get access!" weight='semibold' size='large' />
            </Flex>
        </React.Fragment>
    );
};

export default AccessDenied;
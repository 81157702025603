import { put, call, take, delay } from "redux-saga/effects";
import * as actionCreators from "../actions/NfaFilterActions";
import { httpPost } from "../../service";
import {
  API_BASE_URL,
  API_SECRET_TOKEN,
  NFA_FILTERS_API
} from "../../config";
import { all } from "redux-saga/effects";
import {
  ILOBDataPayload,
  IPPLDataPayload,
  IDealerCodeDataPayload,
  IStateDataPayload,
  ICompetitorDataPayload,
  IRegionDataPayload,
  IPLDataPayload,
} from "../interface";

/********************** Generator Function ********************************************/

//LOB
function* postLobData(payload: {
  data: ILOBDataPayload;
  access_token: string;
}) {
  try {
    yield put(actionCreators.postLobDataLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    };
    const { data } = yield httpPost(API_BASE_URL + NFA_FILTERS_API.GET_LOB_DATA, payload.data, config);

    if (typeof (data) === "object") {
      yield put(actionCreators.postLobDataSuccessAction(data));
    } else {
      yield put(actionCreators.postLobDataErrorAction(data));
    }

  } catch (error: any) {
    yield put(actionCreators.postLobDataErrorAction(error));
  }
}

//PPL
function* postPplData(payload: {
  data: IPPLDataPayload;
  access_token: string;
}) {
  try {
    yield put(actionCreators.postPplDataLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    };
    const { data } = yield httpPost(API_BASE_URL + NFA_FILTERS_API.GET_PPL_DATA, payload.data, config);

    if (typeof (data) === "object") {
      yield put(actionCreators.postPplDataSuccessAction(data));
    } else {
      yield put(actionCreators.postPplDataErrorAction(data));
    }
  } catch (error: any) {
    yield put(actionCreators.postPplDataErrorAction(error));
  }
}

//Deader Code
function* postDealerCodeData(payload: {
  data: IDealerCodeDataPayload;
  access_token: string;
}) {
  try {
    yield put(actionCreators.postDealerCodeDataLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    };
    const { data } = yield httpPost(API_BASE_URL + NFA_FILTERS_API.GET_DEALER_CODE_DATA, payload.data, config);

    if (Array.isArray(data)) {
      yield put(actionCreators.postDealerCodeDataSuccessAction(data));
    } else {
      yield put(actionCreators.postDealerCodeDataErrorAction(data));
    }

  } catch (error: any) {
    yield put(actionCreators.postDealerCodeDataErrorAction(error));
  }
}

//State
function* postStateData(payload: {
  data: IStateDataPayload;
  access_token: string;
}) {
  try {
    yield put(actionCreators.postStateDataLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    };
    const { data } = yield httpPost(API_BASE_URL + NFA_FILTERS_API.GET_STATE_DATA, payload.data, config);

    if (Array.isArray(data)) {
      yield put(actionCreators.postStateDataSuccessAction(data));
    } else {
      yield put(actionCreators.postStateDataErrorAction(data));
    }

  } catch (error: any) {
    yield put(actionCreators.postStateDataErrorAction(error));
  }
}

//Competitor
function* postCompetitorData(payload: {
  data: ICompetitorDataPayload;
  access_token: string;
}) {
  try {
    yield put(actionCreators.postCompetitorDataLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    };
    const { data } = yield httpPost(API_BASE_URL + NFA_FILTERS_API.GET_COMPETITOR_DATA, payload.data, config);

    if (typeof (data) === "object") {
      yield put(actionCreators.postCompetitorDataSuccessAction(data));
    } else {
      yield put(actionCreators.postCompetitorDataErrorAction(data));
    }

  } catch (error: any) {
    yield put(actionCreators.postCompetitorDataErrorAction(error));
  }
}

//Region
function* postRegionData(payload: {
  data: IRegionDataPayload;
  access_token: string;
}) {
  try {
    yield put(actionCreators.postRegionDataLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    };
    const { data } = yield httpPost(API_BASE_URL + NFA_FILTERS_API.GET_REGION_DATA, payload.data, config);

    if (Array.isArray(data)) {
      yield put(actionCreators.postRegionDataSuccessAction(data));
    } else {
      yield put(actionCreators.postRegionDataErrorAction(data));
    }

  } catch (error: any) {
    yield put(actionCreators.postRegionDataErrorAction(error));
  }
}

//PL
function* postPlData(payload: { data: IPLDataPayload; access_token: string; }) {
  try {
    yield put(actionCreators.postPlDataLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    };
    const { data } = yield httpPost(API_BASE_URL + NFA_FILTERS_API.GET_PL_DATA, payload.data, config);

    if (typeof (data) === "object") {
      yield put(actionCreators.postPlDataSuccessAction(data));
    } else {
      yield put(actionCreators.postPlDataErrorAction(data));
    }

  } catch (error: any) {
    yield put(actionCreators.postPlDataErrorAction(error));
  }
}

/********************** Watcher Function ********************************************/

//LOB
export function* watchPostLobData() {
  while (true) {
    const { payload } = yield take(actionCreators.Actions.POST_LOB_DATA);
    yield call(postLobData, payload);
  }
}

//PPL
export function* watchPostPplData() {
  while (true) {
    const { payload } = yield take(actionCreators.Actions.POST_PPL_DATA);
    yield call(postPplData, payload);
  }
}

//Deader Code
export function* watchPostDealerCodeData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.POST_DEALER_CODE_DATA
    );
    yield call(postDealerCodeData, payload);
  }
}

//State
export function* watchPostStateData() {
  while (true) {
    const { payload } = yield take(actionCreators.Actions.POST_STATE_DATA);
    yield call(postStateData, payload);
  }
}

//Competitor
export function* watchPostCompetitorData() {
  while (true) {
    const { payload } = yield take(actionCreators.Actions.POST_COMPETITOR_DATA);
    yield call(postCompetitorData, payload);
  }
}

//Region
export function* watchPostRegionData() {
  while (true) {
    const { payload } = yield take(actionCreators.Actions.POST_REGION_DATA);
    yield call(postRegionData, payload);
  }
}

//PL
export function* watchPostPlData() {
  while (true) {
    const { payload } = yield take(actionCreators.Actions.POST_PL_DATA);
    yield call(postPlData, payload);
  }
}
/********************** Root Saga ********************************************/

function* watchNfaFilterDataSaga() {
  yield all([
    watchPostLobData(),
    watchPostPplData(),
    watchPostDealerCodeData(),
    watchPostStateData(),
    watchPostCompetitorData(),
    watchPostRegionData(),
    watchPostPlData(),
  ]);
}
export default watchNfaFilterDataSaga;

import { put, call, take, delay } from "redux-saga/effects";
import * as actionCreators from "../actions/UserActions";
import { httpGet, httpGetGraph, httpPost } from "../../service";
import {
  API_SECRET_TOKEN,
  API_BASE_URL,
  NFA_USER_API
} from "../../config";
import { all } from "redux-saga/effects";
import { INFADashboardPayload } from "../interface";

/********************** Generator Function ********************************************/

function* getEmployeeIdData(payload: { access_token: string; context: any }) {
  try {
    yield put(actionCreators.getEmployeeIdUsingGraphLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    };
    const { data } = yield httpGetGraph(
      `https://graph.microsoft.com/v1.0/users/${payload.context}?$expand=manager($levels=1)&$select=EmployeeID,mailNickname,mail,userPrincipalName,givenName,surname,displayName`,
      config
    );
    yield put(actionCreators.getEmployeeIdUsingGraphSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.getEmployeeIdUsingGraphErrorAction(error));
  }
}

// User Access Token
function* getUserRolesList(payload: string) {
  try {
    yield put(actionCreators.getUserRolesListLoadingAction());
    const { data } = yield httpGet(API_BASE_URL + NFA_USER_API.GET_ROLES_LIST + `userid=${payload}&&usertype=E`, {
      headers: {
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    });
    if (typeof data !== "string") {
      yield put(actionCreators.getUserRolesListSuccessAction(data));
    } else { yield put(actionCreators.getUserRolesListErrorAction(data)); }

  } catch (error: any) {
    yield put(actionCreators.getUserRolesListErrorAction(error));
  }
}

// User Role
function* getUserRoleData(payload: {
  RoleType: string;
  AppId: string;
  access_token: string;
}) {
  try {
    yield put(actionCreators.getUserRoleLoadingAction());
    // const config = {
    //   headers: {
    //     // Authorization: `Bearer ${payload.access_token}`,
    //     token: API_SECRET_TOKEN,
    //     "x-teams-origin": window.location.origin,
    //   },
    // };
    const { data } = yield httpGet(
      API_BASE_URL + NFA_USER_API.GET_ROLE_DATA + `RoleType=${payload.RoleType}&AppId=${payload.AppId}`,
      // config
    );

    if (Array.isArray(data)) {
      yield put(actionCreators.getUserRoleSuccessAction(data));
    } else {
      yield put(actionCreators.getUserRoleErrorAction(data));
    }
  } catch (error: any) {
    yield put(actionCreators.getUserRoleErrorAction(error));
  }
}

// User Position
function* getUserPositionData(payload: {
  pRole: string;
  access_token: string;
}) {
  try {
    yield put(actionCreators.getUserPositionLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    };
    const { data } = yield httpGet(
      API_BASE_URL + NFA_USER_API.GET_POSITION_DATA + `pRole=${payload.pRole}`,
      // config
    );

    if (Array.isArray(data)) {
      yield put(actionCreators.getUserPositionSuccessAction(data));
    } else {
      yield put(actionCreators.getUserPositionErrorAction(data));
    }
  } catch (error: any) {
    yield put(actionCreators.getUserPositionErrorAction(error));
  }
}

// NFA Dashboard
function* postNfaDashboardData(payload: {
  data: INFADashboardPayload;
  access_token: string;
}) {
  try {
    yield put(actionCreators.postNfaDashboardLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    };
    const { data } = yield httpPost(
      API_BASE_URL + NFA_USER_API.GET_DASHBOARD_DATA,
      payload.data,
      // config
    );
    if (typeof data === "object") {
      yield put(actionCreators.postNfaDashboardSuccessAction(data));
    } else {
      yield put(actionCreators.postNfaDashboardErrorAction(data));
    }

  } catch (error: any) {
    yield put(actionCreators.postNfaDashboardErrorAction(error));
  }
}
/********************** Watcher Function ********************************************/

// User Access Token
export function* watchGetUserRolesList() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_USER_ROLES_LIST
    );
    yield call(getUserRolesList, payload);
  }
}

// User Role
export function* watchGetRoleData() {
  while (true) {
    const { payload } = yield take(actionCreators.Actions.GET_USER_ROLE);
    yield call(getUserRoleData, payload);
  }
}

// User Position
export function* watchGetPositionData() {
  while (true) {
    const { payload } = yield take(actionCreators.Actions.GET_USER_POSITION);
    yield call(getUserPositionData, payload);
  }
}

// NFA Dashboard
export function* watchPostNfaDashboardData() {
  while (true) {
    const { payload } = yield take(actionCreators.Actions.POST_NFA_DASHBOARD);
    yield call(postNfaDashboardData, payload);
  }
}

export function* watchGetEmployeeIdData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_EMP_ID_FROM_GRAPH
    );
    yield call(getEmployeeIdData, payload);
  }
}
/********************** Root Saga ********************************************/

function* watchUserDataSaga() {
  yield all([
    watchGetUserRolesList(),
    watchGetRoleData(),
    watchGetPositionData(),
    watchPostNfaDashboardData(),
    watchGetEmployeeIdData()
  ]);
}
export default watchUserDataSaga;

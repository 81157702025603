import { combineReducers } from "redux";
import {
  Actions,
  IGetUserRolesList,
  IGetUserRole,
  IGetUserPosition,
  IPostNfaDashboard,
  IGetEmployeeUsingGraph,
} from "../actions/UserActions";

export const getEmployeeIdFromAdReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_EMP_ID_FROM_GRAPH_LOADING:
    case Actions.GET_EMP_ID_FROM_GRAPH_ERROR:
    case Actions.GET_EMP_ID_FROM_GRAPH_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

//User Access Token Reducer
export const getUserRolesListReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_USER_ROLES_LIST_LOADING:
    case Actions.GET_USER_ROLES_LIST_SUCCESS:
    case Actions.GET_USER_ROLES_LIST_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

//User Role Reducer
export const getUserRoleReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_USER_ROLE_LOADING:
    case Actions.GET_USER_ROLE_SUCCESS:
    case Actions.GET_USER_ROLE_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

//User Position Reducer
export const getUserPositionReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.GET_USER_POSITION_LOADING:
    case Actions.GET_USER_POSITION_SUCCESS:
    case Actions.GET_USER_POSITION_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

//NFA Dashboard Reducer
export const postNfaDashboardReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.POST_NFA_DASHBOARD_LOADING:
    case Actions.POST_NFA_DASHBOARD_SUCCESS:
    case Actions.POST_NFA_DASHBOARD_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export interface IUserData {
  getEmpDataFromAD: IGetEmployeeUsingGraph;
  getUserRolesListData: IGetUserRolesList;
  getUserRoleData: IGetUserRole;
  getUserPositionData: IGetUserPosition;
  postNfaDashboardData: IPostNfaDashboard;
}

const userData = combineReducers({
  getEmpDataFromAD: getEmployeeIdFromAdReducer,
  getUserRolesListData: getUserRolesListReducer,
  getUserRoleData: getUserRoleReducer,
  getUserPositionData: getUserPositionReducer,
  postNfaDashboardData: postNfaDashboardReducer,
});
export default userData;

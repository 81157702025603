import { combineReducers } from "redux";
import {
  Actions,
  IPostLOBData,
  IPostPPLData,
  IPostDealerCodeData,
  IPostStateData,
  IPostCompetitorData,
  IPostRegionData,
  IPostPLData,
} from "../actions/NfaFilterActions";

//LOB Reducer
export const postLobDataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.POST_LOB_DATA_LOADING:
    case Actions.POST_LOB_DATA_SUCCESS:
    case Actions.POST_LOB_DATA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

//PPL Reducer
export const postPplDataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.POST_PPL_DATA_LOADING:
    case Actions.POST_PPL_DATA_SUCCESS:
    case Actions.POST_PPL_DATA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

//Deader Code Reducer
export const postDealerCodeDataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.POST_DEALER_CODE_DATA_LOADING:
    case Actions.POST_DEALER_CODE_DATA_SUCCESS:
    case Actions.POST_DEALER_CODE_DATA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

//State Reducer
export const postStateDataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.POST_STATE_DATA_LOADING:
    case Actions.POST_STATE_DATA_SUCCESS:
    case Actions.POST_STATE_DATA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

//Competitor Reducer
export const postCompetitorDataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.POST_COMPETITOR_DATA_LOADING:
    case Actions.POST_COMPETITOR_DATA_SUCCESS:
    case Actions.POST_COMPETITOR_DATA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

//Region Reducer
export const postRegionDataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.POST_REGION_DATA_LOADING:
    case Actions.POST_REGION_DATA_SUCCESS:
    case Actions.POST_REGION_DATA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

//PL Reducer
export const postPlDataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.POST_PL_DATA_LOADING:
    case Actions.POST_PL_DATA_SUCCESS:
    case Actions.POST_PL_DATA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export interface INfaFilterData {
  lobData: IPostLOBData;
  pplData: IPostPPLData;
  dealerCodeData: IPostDealerCodeData;
  stateData: IPostStateData;
  CompetitorCodeData: IPostCompetitorData;
  RegionData: IPostRegionData;
  plData: IPostPLData;
}

const nfaFilterData = combineReducers({
  lobData: postLobDataReducer,
  pplData: postPplDataReducer,
  dealerCodeData: postDealerCodeDataReducer,
  stateData: postStateDataReducer,
  CompetitorCodeData: postCompetitorDataReducer,
  RegionData: postRegionDataReducer,
  plData: postPlDataReducer,
});
export default nfaFilterData;

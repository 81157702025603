import { combineReducers } from "redux";
import {
  Actions,
  IPostBudgetInfoData,
  IPostProvisionDetailData,
} from "../actions/NfaReportActions";

//Budget Info Reducer
export const postBudgetInfDataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.POST_BUDGE_INFO_DATA_LOADING:
    case Actions.POST_BUDGE_INFO_DATA_SUCCESS:
    case Actions.POST_BUDGE_INFO_DATA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

//Provision Detail Reducer
export const postProvisionDetailDataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Actions.POST_PROVISION_DETAIL_DATA_LOADING:
    case Actions.POST_PROVISION_DETAIL_DATA_SUCCESS:
    case Actions.POST_PROVISION_DETAIL_DATA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export interface INfaReportData {
  budgetInfoData: IPostBudgetInfoData;
  provisionDetailData: IPostProvisionDetailData;
}

const nfaReportData = combineReducers({
  budgetInfoData: postBudgetInfDataReducer,
  provisionDetailData: postProvisionDetailDataReducer,
});
export default nfaReportData;

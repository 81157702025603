import { IGetUserPositionObject } from "./UserActions";

export const Actions = {
  DEVICE_DIMESIONS: "DEVICE_DIMESIONS",
  SET_BUDGET_FIELD_VIEW: "SET_BUDGET_FIELD_VIEW",
  SET_DOCUMENT_EXPAND: "SET_DOCUMENT_EXPAND",
  SET_PATH: "SET_PATH",
  SELECTED_POSITION_DATA: "SELECTED_POSITION_DATA",
  USER_ROLE: "USER_ROLE",
  MAIN_ACTIVE_POSITION: "MAIN_ACTIVE_POSITION",
};

export const setDimensionByDevice = (value: {
  deviceWidth: number;
  deviceHeight: number;
}) => {
  return {
    type: Actions.DEVICE_DIMESIONS,
    payload: value,
  };
};

export const setBudgetFieldView = (value: boolean) => {
  return {
    type: Actions.SET_BUDGET_FIELD_VIEW,
    payload: value,
  };
};

export const setDocumentExpand = (value: boolean) => {
  return {
    type: Actions.SET_DOCUMENT_EXPAND,
    payload: value,
  };
};

export const setPath = (value: string) => {
  return {
    type: Actions.SET_PATH,
    payload: value
  };
};

/*********************** Selected Position Array ************************************/
export const selectedPositionData = (data: IGetUserPositionObject[]) => {
  return {
    type: Actions.SELECTED_POSITION_DATA,
    payload: data
  };
};

/*********************** User Role ************************************/
export const userRole = (role: string) => {
  return {
    type: Actions.USER_ROLE,
    payload: role
  };
};

/*********************** MAIN USER POsition ************************************/
export const setMainActivePosition = (position: {
  Role: string;
  Type: string;
  BU: string;
  Position: string;
}) => {
  return {
    type: Actions.MAIN_ACTIVE_POSITION,
    payload: position
  };
};


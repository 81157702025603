import { put, call, take, delay } from "redux-saga/effects";
import * as actionCreators from "../actions/NfaReportActions";
import { httpPost } from "../../service";
import {
  API_BASE_URL,
  API_SECRET_TOKEN, NFA_REPORT_API
} from "../../config";
import { all } from "redux-saga/effects";
import { IBudgeInfoPayload, IProvisionDetailPayload } from "../interface";

/********************** Generator Function ********************************************/

//Budge Info
function* postBudgeInfoData(payload: {
  data: IBudgeInfoPayload;
  access_token: string;
}) {
  try {
    yield put(actionCreators.postBudgetInfoDataLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    };

    const { data } = yield httpPost(API_BASE_URL + NFA_REPORT_API.GET_BUDGE_INFO_DATA, payload.data, config);

    if (typeof data === "object") {
      yield put(actionCreators.postBudgetInfoDataSuccessAction(data));
    } else {
      yield put(actionCreators.postBudgetInfoDataErrorAction(data));
    }

  } catch (error: any) {
    yield put(actionCreators.postBudgetInfoDataErrorAction(error));
  }
}

//Provision Detail
function* postProvisionDetailData(payload: {
  data: IProvisionDetailPayload;
  access_token: string;
}) {
  try {
    yield put(actionCreators.postProvisionDetailDataLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    };
    const { data } = yield httpPost(
      API_BASE_URL + NFA_REPORT_API.GET_PROVISION_DETAIL_DATA,
      payload.data,
      config
    );

    yield put(actionCreators.postProvisionDetailDataSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.postProvisionDetailDataErrorAction(error));
  }
}

/********************** Watcher Function ********************************************/

//Budge Info
export function* watchPostBudgeInfoData() {
  while (true) {
    const { payload } = yield take(actionCreators.Actions.POST_BUDGE_INFO_DATA);
    yield call(postBudgeInfoData, payload);
  }
}

//Budge Info
export function* watchPostProvisionDetailData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.POST_PROVISION_DETAIL_DATA
    );
    yield call(postProvisionDetailData, payload);
  }
}

/********************** Root Saga ********************************************/

function* watchNfaReportDataSaga() {
  yield all([watchPostBudgeInfoData(), watchPostProvisionDetailData()]);
}
export default watchNfaReportDataSaga;

import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import Dashboard from "../container/Dashboard";
// import BudgetApproval from "../container/BudgetApproval";
// import BudgetRequest from "../container/BudgetRequest";
// import NoteForApproval from "../container/NoteForApproval";
// import ReportMIS from "../container/ReportMIS";
// import NFAProvisionReport from "../components/NFAProvisionReport";
import Sidebar from "../components/Sidebar";

import { IState } from "../App";
import LazyLoading from "../components/LazyLoading";

const Dashboard = React.lazy(() => import("../container/Dashboard"));
const BudgetApproval = React.lazy(() => import("../container/BudgetApproval"));
const BudgetRequest = React.lazy(() => import("../container/BudgetRequest"));
const NoteForApproval = React.lazy(() => import("../container/NoteForApproval"));
const ReportMIS = React.lazy(() => import("../container/ReportMIS"));
const NFAProvisionReport = React.lazy(() => import("../components/NFAProvisionReport"));
// const Sidebar = React.lazy(() => import("../components/Sidebar"));

interface IRoutesProps {
    QueryData: IState;
}

const RouteList: React.FC<IRoutesProps> = ({ QueryData }) => {
    const router = createBrowserRouter(
        [
            {
                path: "/",
                element:
                    <Suspense fallback={<LazyLoading />}>
                        <Sidebar QueryData={QueryData}><Dashboard QueryData={QueryData} /></Sidebar>
                    </Suspense>
            },
            {
                path: "/budget-approval",
                element:
                    <Suspense fallback={<LazyLoading />}>
                        <Sidebar QueryData={QueryData}><BudgetApproval QueryData={QueryData} /></Sidebar>
                    </Suspense>
            },
            {
                path: "/budget",
                element:
                    <Suspense fallback={<LazyLoading />}>
                        <Sidebar QueryData={QueryData}><BudgetRequest /></Sidebar>
                    </Suspense>
            },
            {
                path: "/nfa-approval",
                element:
                    <Suspense fallback={<LazyLoading />}>
                        <Sidebar QueryData={QueryData}><NoteForApproval QueryData={QueryData} /></Sidebar>
                    </Suspense>
            },
            {
                path: "/report-mis",
                element:
                    <Suspense fallback={<LazyLoading />}>
                        <Sidebar QueryData={QueryData}><ReportMIS QueryData={QueryData} /></Sidebar>
                    </Suspense>
            },
            {
                path: "/nfa-provision-report",
                element:
                    <Suspense fallback={<LazyLoading />}>
                        <Sidebar QueryData={QueryData}><NFAProvisionReport QueryData={QueryData} /></Sidebar>
                    </Suspense>
            }
        ]
    );
    return <RouterProvider router={router} />;
};


export default RouteList;
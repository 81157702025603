import { put, call, take, delay, takeLatest, debounce } from "redux-saga/effects";
import moment from "moment";
import * as actionCreators from "../actions/NfaApprovalActions";
import { httpPost } from "../../service";
import {
  API_BASE_URL,
  API_SECRET_TOKEN,
  NFA_APPROVAL_API
} from "../../config";
import { all } from "redux-saga/effects";
import {
  IUserPreferenceDataPayload,
  ISetUserPreferencePayload,
  IGetNFADetailDataPayload,
  IDownloadNFADetailDataPayload,
  INFAApprovePayload,
  INFARejectPayload,
} from "../interface";
import {
  SetColumnPreferenceResponse,
  tableColumnsResponse,
  tableRowsResponse,
} from "../responses";

/********************** Generator Function ********************************************/

//User Preference
function* getUserPreferenceData(actions: {
  type: string, payload: {
    data: IUserPreferenceDataPayload;
    access_token: string;
  };
}) {
  try {
    yield put(actionCreators.getUserPreferenceDataLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${actions.payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    };

    const { data } = yield httpPost(
      API_BASE_URL + NFA_APPROVAL_API.GET_USER_PREFERENCE_DATA,
      actions.payload.data,
      config
    );
    if (typeof (data) === "object") {
      yield put(actionCreators.getUserPreferenceDataSuccessAction({ ...data, role: actions.payload.data.role }));
    } else {
      yield put(actionCreators.getUserPreferenceDataErrorAction({ ...data, role: actions.payload.data.role }));
    }

  } catch (error: any) {
    yield put(actionCreators.getUserPreferenceDataErrorAction(error));
  }
}

//Set User Preference
function* setUserPreferenceData(actions: {
  type: string, payload: {
    data: ISetUserPreferencePayload;
    access_token: string;
  };
}) {
  try {
    yield put(actionCreators.setUserPreferenceDataLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${actions.payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    };

    const { data } = yield httpPost(
      API_BASE_URL + NFA_APPROVAL_API.SET_USER_PREFERENCE_DATA,
      actions.payload.data,
      config
    );
    if (typeof (data) === "object") {
      yield put(actionCreators.setUserPreferenceDataSuccessAction(data));
    } else {
      yield put(actionCreators.setUserPreferenceDataErrorAction(data));
    }

  } catch (error: any) {
    yield put(actionCreators.setUserPreferenceDataErrorAction(error));
  }
}

//NFA Details
function* postNfaDetailData(actions: {
  type: string, payload: {
    data: IGetNFADetailDataPayload;
    access_token: string;
  };
}) {
  try {
    yield put(actionCreators.postNfaDetailDataLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${actions.payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    };

    const { data } = yield httpPost(API_BASE_URL + NFA_APPROVAL_API.GET_NFA_DETAIL_DATA, actions.payload.data, config);

    if (typeof (data) === "object") {
      yield put(actionCreators.postNfaDetailDataSuccessAction(data));
    } else {
      yield put(actionCreators.postNfaDetailDataErrorAction(data));
    }

  } catch (error: any) {
    yield put(actionCreators.postNfaDetailDataErrorAction(error));
  }
}

//export to excel API
function* postDownloadNfaDetailData(payload: {
  data: IDownloadNFADetailDataPayload;
  access_token: string;
}) {
  try {
    yield put(actionCreators.postDownloadNfaDetailDataLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
      responseType: "blob"
    };
    const { data, headers, } = yield httpPost(
      API_BASE_URL + NFA_APPROVAL_API.GET_DOWNLOAD_NFA_DETAIL_DATA,
      payload.data,
      config
    );

    const contentType = headers["content-type"];
    const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    const currentDate = moment().format("MM-DD-YYYY");
    a.download = `NFAPendingList-${currentDate}.xls`; // Set the desired file name here
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

    yield put(actionCreators.postDownloadNfaDetailDataSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.postDownloadNfaDetailDataErrorAction(error));
  }
}

//Submit API(Approve)
function* postNfaApproveData(payload: {
  data: INFAApprovePayload;
  access_token: string;
}) {
  try {
    yield put(actionCreators.postNfaApproveDataLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    };
    const { data } = yield httpPost(API_BASE_URL + NFA_APPROVAL_API.POST_NFA_APPROVE, payload.data, config);

    yield put(actionCreators.postNfaApproveDataSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.postNfaApproveDataErrorAction(error));
  }
}

//Submit API(send for reconsideration, reject)
function* postNfaRejectData(payload: {
  data: INFARejectPayload;
  access_token: string;
}) {
  try {
    yield put(actionCreators.postNfaRejectDataLoadingAction());
    const config = {
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        token: API_SECRET_TOKEN,
        "x-teams-origin": window.location.origin,
      },
    };
    const { data } = yield httpPost(API_BASE_URL + NFA_APPROVAL_API.POST_NFA_REJECT, payload.data, config);

    yield put(actionCreators.postNfaRejectDataSuccessAction(data));
  } catch (error: any) {
    yield put(actionCreators.postNfaRejectDataErrorAction(error));
  }
}

/********************** Watcher Function ********************************************/

//Get User Preference
export function* watchGetUserPreferenceData() {
  yield takeLatest(actionCreators.Actions.GET_USER_PREFERENCE_DATA, getUserPreferenceData);
  // yield debounce(1000, actionCreators.Actions.GET_USER_PREFERENCE_DATA, getUserPreferenceData)
}

//Set User Preference
export function* watchSetUserPreferenceData() {
  yield takeLatest(actionCreators.Actions.SET_USER_PREFERENCE_DATA, setUserPreferenceData);
}

//NFA Details
export function* watchPostNfaDetailData() {
  yield takeLatest(actionCreators.Actions.GET_NFA_DETAIL_DATA, postNfaDetailData);
}

//export to excel API
export function* watchPostDownloadNfaDetailData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.GET_DOWNLOAD_NFA_DETAIL_DATA
    );
    yield call(postDownloadNfaDetailData, payload);
  }
}

//Submit API(Approve)
export function* watchPostNfaApproveData() {
  while (true) {
    const { payload } = yield take(
      actionCreators.Actions.POST_NFA_APPROVE_DATA
    );
    yield call(postNfaApproveData, payload);
  }
}

//Submit API(send for reconsideration, reject)
export function* watchPostNfaRejectData() {
  while (true) {
    const { payload } = yield take(actionCreators.Actions.POST_NFA_REJECT_DATA);
    yield call(postNfaRejectData, payload);
  }
}
/********************** Root Saga ********************************************/

function* watchNfaApprovalDataSaga() {
  yield all([
    watchGetUserPreferenceData(),
    watchSetUserPreferenceData(),
    watchPostNfaDetailData(),
    watchPostDownloadNfaDetailData(),
    watchPostNfaApproveData(),
    watchPostNfaRejectData(),
  ]);
}
export default watchNfaApprovalDataSaga;
